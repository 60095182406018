import { useTranslation } from 'react-i18next';
import { cn } from '~/lib/util';

type SpinnerProps = {
    size?: 'sm' | 'md' | 'lg';
    classes?: string;
};

export default function Spinner({ size, classes }: SpinnerProps) {
    const { t } = useTranslation();
    size = size || 'md';

    return (
        <div
            className={cn(
                'animate-spin inline-block border-[3px] border-current border-t-transparent text-r2o-blue rounded-full dark:text-r2o-blue',
                {
                    'w-4 h-4': size === 'sm',
                    'w-6 h-6': size === 'md',
                    'w-8 h-8': size === 'lg',
                },
                classes
            )}
            role='status'
            aria-label='loading'
        >
            <span className='sr-only'>{t('loading')}...</span>
        </div>
    );
}
